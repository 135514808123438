import { ToggleSwitch } from '../../components/ToggleSwitch'
import { UserOrganizationListCell } from '../../components/UserOrganizationListCell'
import {
  SHOULD_INCLUDE_ADMIN_COLUMN,
  SHOULD_INCLUDE_ORGANIZATION_COLUMN,
  TableOptions,
  TableOptionValues,
} from '../../configs/constants'
import { UserObj } from '../../store/slices/userlist/slice'

export const USERLIST_COLUMNS_CONFIG = [
  {
    [TableOptions.accessorKey]: TableOptionValues.id,
    [TableOptions.header]: 'ID',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.firstName,
    [TableOptions.header]: 'First Name',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.lastName,
    [TableOptions.header]: 'Last Name',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.email,
    [TableOptions.header]: 'Email',
  },
  SHOULD_INCLUDE_ORGANIZATION_COLUMN && {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [TableOptions.cell]: ({ row }: any) => {
      return <UserOrganizationListCell groups={row.original.groups} />
    },
    [TableOptions.header]: 'Organization (s)',
  },
  {
    [TableOptions.cell]: ({ row }: { row: { original: UserObj } }) => {
      return (
        <ToggleSwitch
          user={row.original}
          payload={{ enabled: !row.original.enabled }}
        />
      )
    },
    [TableOptions.header]: 'Enabled',
  },
  SHOULD_INCLUDE_ADMIN_COLUMN && {
    [TableOptions.cell]: ({ row }: { row: { original: UserObj } }) => {
      return (
        <ToggleSwitch
          user={row.original}
          payload={{ admin: !row.original.admin }}
        />
      )
    },
    [TableOptions.header]: 'Admin',
  },
].filter((v) => v)
