import { Chip } from '@mui/material'
import moment from 'moment'
import { InvitationTableActions } from '../../components/InvitationTableActions'
import { InvitationObj } from '../../store/slices/invitation/slice'
import {
  DATETIME_FORMAT,
  SHOULD_INCLUDE_ORGANIZATIONS,
  TableOptions,
} from '../../configs/constants'

const organizationColumns = [
  {
    [TableOptions.header]: 'Organization',
    [TableOptions.accessorKey]: 'groupName',
  },
  {
    [TableOptions.header]: 'Organization ID',
    [TableOptions.accessorKey]: 'groupId',
  },
]

const invitationRequiredColumns = [
  {
    [TableOptions.header]: 'ID',
    [TableOptions.accessorKey]: 'id',
  },
  {
    [TableOptions.header]: 'Email',
    [TableOptions.accessorKey]: 'email',
  },
  {
    [TableOptions.header]: 'Created At',
    [TableOptions.accessorFn]: ({ createdAt }: InvitationObj) =>
      moment(createdAt).format(DATETIME_FORMAT),
  },
  {
    [TableOptions.header]: 'Expiry',
    [TableOptions.accessorFn]: ({ expiryTime }: InvitationObj) =>
      moment.unix(expiryTime).fromNow(),
  },
  {
    [TableOptions.header]: 'Status',
    [TableOptions.accessorKey]: 'status',
    [TableOptions.cell]: ({ row }: { row: { original: InvitationObj } }) => {
      const getColor = () => {
        switch (row.original.status) {
          case 'cancelled':
            return 'error'
          case 'accepted':
            return 'success'
          case 'pending':
            return 'info'
          case 'expired':
            return 'warning'
        }
      }
      return (
        <Chip
          size="small"
          label={row.original.status}
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.6rem',
          }}
          color={getColor()}
        />
      )
    },
  },
  {
    [TableOptions.header]: 'Actions',
    [TableOptions.id]: 'actions',
    [TableOptions.cell]: ({ row }: { row: { original: InvitationObj } }) => (
      <InvitationTableActions invitation={row.original} />
    ),
  },
]

export const INVITATION_COLUMNS_CONFIG = invitationRequiredColumns
  .slice(0, 2)
  .concat(SHOULD_INCLUDE_ORGANIZATIONS ? organizationColumns : [])
  .concat(invitationRequiredColumns.slice(2))
