import React, { useMemo } from 'react'
import { SearchOutlined } from '@mui/icons-material'
import { InputAdornment, Pagination, TextField } from '@mui/material'
import { debounce } from 'lodash'
import {
  StyledContentWrapper,
  StyledTableTitle,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
} from './elements'
import {
  setGroupUsersPaginationAction,
  setNonGroupUsersPaginationAction,
} from '../../store/slices/groupUser/slice'
import {
  selectGroupUsers,
  selectGroupUsersPagination,
  selectNonGroupUsers,
  selectNonGroupUsersPagination,
} from '../../store/slices/groupUser/selectors'
import { Table } from '../Table'
import {
  getAddGroupUsersColumnsConfig,
  getRemoveGroupUsersColumnsConfig,
} from './tableConfig'
import {
  addGroupUserAction,
  fetchGroupUserlistAction,
  fetchNonGroupUserlistAction,
  removeGroupUserAction,
} from '../../store/slices/groupUser/actions'
import { Snackbar } from '../SnackbarConfigurator'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

interface ModalBodyPayload {
  groupId: string
  groupName: string
}

const GroupManageModalBody = ({ groupId, groupName }: ModalBodyPayload) => {
  const dispatch = useAppDispatch()
  const groupUserList = useAppSelector(selectGroupUsers)
  const nonGroupUserList = useAppSelector(selectNonGroupUsers)
  const {
    limit: limitGroupUser,
    pageNumber: pageNumberGroupUser,
    search: searchGroupUser,
    total: totalGroupUser,
  } = useAppSelector(selectGroupUsersPagination)
  const {
    limit: limitNonGroupUser,
    pageNumber: pageNumberNonGroupUser,
    search: searchNonGroupUser,
    total: totalNonGroupUser,
  } = useAppSelector(selectNonGroupUsersPagination)

  const pageChangeHandlerGroupUser = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      setGroupUsersPaginationAction({
        limit: limitGroupUser,
        pageNumber: value,
        search: searchGroupUser,
        total: totalGroupUser,
      })
    )
    dispatch(
      fetchGroupUserlistAction({
        inclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error('An error occurred while fetching organization users!')
        },
      })
    )
  }

  const pageChangeHandlerNonGroupUser = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      setNonGroupUsersPaginationAction({
        limit: limitNonGroupUser,
        pageNumber: value,
        search: searchNonGroupUser,
        total: totalNonGroupUser,
      })
    )
    dispatch(
      fetchNonGroupUserlistAction({
        exclGroupId: groupId,
        errorHandler: () => {
          Snackbar.error(
            'An error occurred while fetching non organization users!'
          )
        },
      })
    )
  }

  const debouncedSearchChangeHandlerGroupUser = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(
          setGroupUsersPaginationAction({
            limit: limitGroupUser,
            pageNumber: 1,
            search: query,
            total: totalGroupUser,
          })
        )
        dispatch(
          fetchGroupUserlistAction({
            inclGroupId: groupId,
            errorHandler: () => {
              Snackbar.error(
                'An error occurred while fetching organization users!'
              )
            },
          })
        )
      }, 500),
    [dispatch, groupId, limitGroupUser, totalGroupUser]
  )

  const searchChangeHandlerGroupUser = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandlerGroupUser(event.target.value)
  }

  const debouncedSearchChangeHandlerNonGroupUser = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(
          setNonGroupUsersPaginationAction({
            limit: limitNonGroupUser,
            pageNumber: 1,
            search: query,
            total: totalNonGroupUser,
          })
        )
        dispatch(
          fetchNonGroupUserlistAction({
            exclGroupId: groupId,
            errorHandler: () => {
              Snackbar.error(
                'An error occurred while fetching non organization users!'
              )
            },
          })
        )
      }, 500),
    [dispatch, groupId, limitNonGroupUser, totalNonGroupUser]
  )

  const searchChangeHandlerNonGroupUser = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandlerNonGroupUser(event.target.value)
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Existing Users</StyledTableTitle>
        <TextField
          placeholder="Search"
          sx={{ m: 1, width: '25ch' }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandlerGroupUser}
        />
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table
          columns={getRemoveGroupUsersColumnsConfig(
            groupId,
            groupName,
            removeGroupUserAction
          )}
          data={groupUserList}
          maxHeight={350}
          stickyHeader={true}
        />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(totalGroupUser / limitGroupUser)}
            color="secondary"
            page={pageNumberGroupUser}
            onChange={pageChangeHandlerGroupUser}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>

      <StyledTableHeaderWrapper>
        <StyledTableTitle>Add User</StyledTableTitle>
        <TextField
          placeholder="Search"
          sx={{ m: 1, width: '25ch' }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandlerNonGroupUser}
        />
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table
          columns={getAddGroupUsersColumnsConfig(
            groupId,
            groupName,
            addGroupUserAction
          )}
          data={nonGroupUserList}
          maxHeight={350}
          stickyHeader={true}
        />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(totalNonGroupUser / limitNonGroupUser)}
            color="secondary"
            page={pageNumberNonGroupUser}
            onChange={pageChangeHandlerNonGroupUser}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default GroupManageModalBody
