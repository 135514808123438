import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AddEntityUserButton } from '../AddEntityUserButton'
import { RemoveEntityUserButton } from '../RemoveEntityUserButton'
import { AddRemoveEntityUserPayload } from '../../store/slices/models'
import { TableOptions, TableOptionValues } from '../../configs/constants'

export const getRemoveGroupUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>
) => {
  return [
    {
      [TableOptions.accessorKey]: TableOptionValues.firstName,
      [TableOptions.header]: 'First Name',
    },
    {
      [TableOptions.accessorKey]: TableOptionValues.lastName,
      [TableOptions.header]: 'Last Name',
    },
    {
      [TableOptions.accessorKey]: TableOptionValues.email,
      [TableOptions.header]: 'Email',
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [TableOptions.cell]: ({ row }: any) => {
        return (
          <RemoveEntityUserButton
            userId={row.original.id}
            userEmail={row.original.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
          />
        )
      },
      [TableOptions.header]: 'Action',
    },
  ]
}

export const getAddGroupUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>
) => {
  return [
    {
      [TableOptions.accessorKey]: TableOptionValues.firstName,
      [TableOptions.header]: 'First Name',
    },
    {
      [TableOptions.accessorKey]: TableOptionValues.lastName,
      [TableOptions.header]: 'Last Name',
    },
    {
      [TableOptions.accessorKey]: TableOptionValues.email,
      [TableOptions.header]: 'Email',
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [TableOptions.cell]: ({ row }: any) => {
        return (
          <AddEntityUserButton
            userId={row.original.id}
            userEmail={row.original.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
          />
        )
      },
      [TableOptions.header]: 'Action',
    },
  ]
}
