import { GroupManageButton } from '../../components/GroupManageButton'
import { TableOptions, TableOptionValues } from '../../configs/constants'

export const ORGANIZATION_COLUMNS_CONFIG = [
  {
    [TableOptions.header]: 'ID',
    [TableOptions.accessorKey]: TableOptionValues.id,
  },
  {
    [TableOptions.header]: 'Name',
    [TableOptions.accessorKey]: TableOptionValues.name,
  },
  {
    [TableOptions.header]: 'Description',
    [TableOptions.accessorKey]: TableOptionValues.description,
  },
  {
    [TableOptions.header]: 'User Count',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [TableOptions.cell]: ({ row }: any) => {
      return row.original.userCount === 0 ? '-' : row.original.userCount
    },
  },
  {
    [TableOptions.header]: 'Action',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [TableOptions.cell]: ({ row }: any) => {
      return (
        <GroupManageButton
          groupId={row.original.id}
          groupName={row.original.name}
        />
      )
    },
  },
]
