import React from 'react'
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from '@tanstack/react-table'
import { StyledTable, StyledTableRow } from './elements'

interface TableProps<T extends object> {
  columns: ColumnDef<T>[]
  data: T[]
  maxHeight?: number
  stickyHeader?: boolean
}

const Table: React.FC<TableProps<object>> = ({
  columns,
  data,
  maxHeight = 2000,
  stickyHeader = false,
}) => {
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })
  /* react-table props provide key; and so, no need to explicity mention key on the maps*/
  /* eslint-disable react/jsx-key */
  return (
    <TableContainer component={Paper} style={{ maxHeight: maxHeight }}>
      <StyledTable stickyHeader={stickyHeader}>
        <TableHead>
          {getHeaderGroups().map((headerGroup) => (
            <TableRow {...headerGroup} key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <TableCell
                  colSpan={header.colSpan}
                  key={header.id}
                  align={index === 0 ? 'left' : 'center'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {getRowModel().rows.length === 0 && (
            <StyledTableRow>
              <TableCell
                colSpan={getHeaderGroups()[0]?.headers.length || 1}
                align="center"
              >
                <i>There are no records to display</i>
              </TableCell>
            </StyledTableRow>
          )}
          {getRowModel().rows.map((row) => {
            return (
              <StyledTableRow {...row} key={row.id}>
                {row.getAllCells().map((cell, index) => {
                  return (
                    <TableCell
                      key={cell.id}
                      align={index === 0 ? 'left' : 'center'}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default Table
