import moment from 'moment'
import { NamespaceObj } from '../../../store/slices/namespace/slice'
import {
  DATETIME_FORMAT,
  TableOptions,
  TableOptionValues,
} from '../../../configs/constants'

export const NAMESPACE_COLUMNS_CONFIG = [
  {
    [TableOptions.accessorKey]: TableOptionValues.name,
    [TableOptions.header]: 'Name',
  },
  {
    [TableOptions.accessorKey]: TableOptionValues.description,
    [TableOptions.header]: 'Description',
  },
  {
    [TableOptions.accessorKey]: 'sourceId',
    [TableOptions.header]: 'Source ID',
  },
  {
    [TableOptions.accessorFn]: ({ createdAt }: NamespaceObj) =>
      moment(createdAt).format(DATETIME_FORMAT),
    [TableOptions.header]: 'Created At',
  },
]
