import { PermissionObj } from '../../store/slices/roles/slice'
import { Chip } from '@mui/material'
import { PermissionTableActions } from '../PermissionTableActions'
import { TableOptions } from '../../configs/constants'

export const PERMISSION_COLUMNS_CONFIG = [
  {
    [TableOptions.accessorKey]: 'namespaceName',
    [TableOptions.header]: 'Namespace',
  },
  {
    [TableOptions.accessorKey]: 'entityName',
    [TableOptions.header]: 'Entity',
  },
  {
    [TableOptions.header]: 'Allowed Action',
    [TableOptions.cell]: ({ row }: { row: { original: PermissionObj } }) =>
      row.original.allowedAction === 'read' ? (
        <Chip label="Read" color="secondary" />
      ) : (
        <Chip label="Write" color="success" />
      ),
  },
  {
    [TableOptions.header]: 'Actions',
    [TableOptions.id]: 'actions',
    [TableOptions.cell]: ({ row }: { row: { original: PermissionObj } }) => (
      <PermissionTableActions permission={row.original} />
    ),
  },
]
