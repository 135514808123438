import moment from 'moment'
import { RoleObj } from '../../../store/slices/roles/slice'
import { RoleTableActions } from '../../../components/RoleTableActions'
import {
  DATETIME_FORMAT,
  TableOptions,
  TableOptionValues,
} from '../../../configs/constants'

export const ROLE_COLUMNS_CONFIG = [
  {
    [TableOptions.header]: 'Name',
    [TableOptions.accessorKey]: TableOptionValues.name,
  },
  {
    [TableOptions.header]: 'Description',
    [TableOptions.accessorKey]: TableOptionValues.description,
  },
  {
    [TableOptions.header]: 'Created At',
    [TableOptions.accessorFn]: ({ createdAt }: RoleObj) =>
      moment(createdAt).format(DATETIME_FORMAT),
  },
  {
    [TableOptions.header]: 'Actions',
    [TableOptions.id]: 'actions',
    [TableOptions.cell]: ({ row }: { row: { original: RoleObj } }) => (
      <RoleTableActions role={row.original} />
    ),
  },
]
